import revive_payload_client_LUxhBAnUBH from "/codebuild/output/src1467342663/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.12.0_db0@0.2.3_eslint@8.57.1_ioredis@5.4.2_m_sorrl4vftcouj5hfeu57rcyugi/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_PXNBAIuQgA from "/codebuild/output/src1467342663/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.12.0_db0@0.2.3_eslint@8.57.1_ioredis@5.4.2_m_sorrl4vftcouj5hfeu57rcyugi/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_RDDA5jTwJ4 from "/codebuild/output/src1467342663/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.12.0_db0@0.2.3_eslint@8.57.1_ioredis@5.4.2_m_sorrl4vftcouj5hfeu57rcyugi/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_N9RYDMKwMs from "/codebuild/output/src1467342663/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt-site-config@2.2.21_magicast@0.3.5_rollup@4.32.1_vite@6.0.11_@types+node@22.12.0_jiti@2.4_oy4ouuzxdxjhtciempr5dvhzpq/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_nGY5adxqCt from "/codebuild/output/src1467342663/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.12.0_db0@0.2.3_eslint@8.57.1_ioredis@5.4.2_m_sorrl4vftcouj5hfeu57rcyugi/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_QENnftyPEB from "/codebuild/output/src1467342663/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.12.0_db0@0.2.3_eslint@8.57.1_ioredis@5.4.2_m_sorrl4vftcouj5hfeu57rcyugi/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_agvQIV7O9N from "/codebuild/output/src1467342663/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.12.0_db0@0.2.3_eslint@8.57.1_ioredis@5.4.2_m_sorrl4vftcouj5hfeu57rcyugi/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_XE3Ckh41D3 from "/codebuild/output/src1467342663/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.12.0_db0@0.2.3_eslint@8.57.1_ioredis@5.4.2_m_sorrl4vftcouj5hfeu57rcyugi/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_EvPqyxs3tT from "/codebuild/output/src1467342663/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.32.1_typescript@5.7.3_vue@3.5.13_typescript@5.7.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/codebuild/output/src1467342663/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/.nuxt/components.plugin.mjs";
import prefetch_client_r8FH1teQyK from "/codebuild/output/src1467342663/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.12.0_db0@0.2.3_eslint@8.57.1_ioredis@5.4.2_m_sorrl4vftcouj5hfeu57rcyugi/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_OHU0vggoM9 from "/codebuild/output/src1467342663/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_iqxn2tqxAK from "/codebuild/output/src1467342663/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@nuxtjs+algolia@1.10.2_@vue+server-renderer@3.5.13_vue@3.5.13_typescript@5.7.3___magicast@0.3_c5trayw2456irollaph3u2caui/node_modules/@nuxtjs/algolia/dist/runtime/plugin.mjs";
import fontawesome_mbwqDw1WFx from "/codebuild/output/src1467342663/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@vesp+nuxt-fontawesome@1.2.0_magicast@0.3.5_rollup@4.32.1/node_modules/@vesp/nuxt-fontawesome/dist/runtime/plugins/fontawesome.js";
import nuxt_plugin_vy4Eb6XFoQ from "/codebuild/output/src1467342663/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt-delay-hydration@1.3.8_magicast@0.3.5_rollup@4.32.1/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import switch_locale_path_ssr_AnD000x1Ls from "/codebuild/output/src1467342663/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.32.1_vue@3.5.13_typescript@5.7.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_MzvZCnWn7B from "/codebuild/output/src1467342663/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.32.1_vue@3.5.13_typescript@5.7.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_5clynZCQYE from "/codebuild/output/src1467342663/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@storyblok+nuxt@6.2.2_vue@3.5.13_typescript@5.7.3_/node_modules/@storyblok/nuxt/dist/runtime/plugin.js";
import base64_2hii42QBJd from "/codebuild/output/src1467342663/src/bitbucket.org/magusa/cavu-workspaces/apps/_base/plugins/base64.ts";
import plugin_Bf6tgPr3as from "/codebuild/output/src1467342663/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.32.1_typesc_4zlwkixszfnzvfigkplgvtrqba/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
export default [
  revive_payload_client_LUxhBAnUBH,
  unhead_PXNBAIuQgA,
  router_RDDA5jTwJ4,
  _0_siteConfig_N9RYDMKwMs,
  payload_client_nGY5adxqCt,
  navigation_repaint_client_QENnftyPEB,
  check_outdated_build_client_agvQIV7O9N,
  chunk_reload_client_XE3Ckh41D3,
  plugin_vue3_EvPqyxs3tT,
  components_plugin_KR1HBZs4kY,
  prefetch_client_r8FH1teQyK,
  plugin_OHU0vggoM9,
  plugin_iqxn2tqxAK,
  fontawesome_mbwqDw1WFx,
  nuxt_plugin_vy4Eb6XFoQ,
  switch_locale_path_ssr_AnD000x1Ls,
  i18n_MzvZCnWn7B,
  plugin_5clynZCQYE,
  base64_2hii42QBJd,
  plugin_Bf6tgPr3as
]