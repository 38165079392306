
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46slug_93vRy7Ivtsp5Meta } from "/codebuild/output/src1467342663/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[...slug].vue?macro=true";
import { default as airport_45hotels_45with_45parkingBh9Hg5uN0UMeta } from "/codebuild/output/src1467342663/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/airport-hotels-with-parking.vue?macro=true";
import { default as airport_45parkinghetSl6E4u2Meta } from "/codebuild/output/src1467342663/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/airport-parking.vue?macro=true";
import { default as airport_45shuttlesYh59SL0ur0Meta } from "/codebuild/output/src1467342663/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/airport-shuttles.vue?macro=true";
import { default as guidesr1foFL1p6FMeta } from "/codebuild/output/src1467342663/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/guides.vue?macro=true";
import { default as off_45airport_45parkingYR0yC1fx2eMeta } from "/codebuild/output/src1467342663/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/off-airport-parking.vue?macro=true";
import { default as parking_45couponsa2rBIvcqx9Meta } from "/codebuild/output/src1467342663/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/parking-coupons.vue?macro=true";
import { default as port_45hotels_45with_45parkingVR5uugXOsqMeta } from "/codebuild/output/src1467342663/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/port-hotels-with-parking.vue?macro=true";
import { default as port_45parking2Pd129OYhjMeta } from "/codebuild/output/src1467342663/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/port-parking.vue?macro=true";
import { default as _91result_9398cvKiOBccMeta } from "/codebuild/output/src1467342663/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/flight-status/[result].vue?macro=true";
import { default as healthDfRyRaU5YnMeta } from "/codebuild/output/src1467342663/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/health.vue?macro=true";
import { default as _91code_93_45_91name_93_45_91IATA_93tS134zEkElMeta } from "/codebuild/output/src1467342663/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/hotel-package/[code]-[name]-[IATA].vue?macro=true";
import { default as _91code_93_45_91name_93_45_91IATA_93wn5FpyOTItMeta } from "/codebuild/output/src1467342663/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/hro/[code]-[name]-[IATA].vue?macro=true";
import { default as _91code_93_45_91name_93_45_91IATA_93ZamszdxpegMeta } from "/codebuild/output/src1467342663/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/parking/[code]-[name]-[IATA].vue?macro=true";
import { default as component_45stubytvoIygjH1Meta } from "/codebuild/output/src1467342663/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.12.0_db0@0.2.3_eslint@8.57.1_ioredis@5.4.2_m_sorrl4vftcouj5hfeu57rcyugi/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubytvoIygjH1 } from "/codebuild/output/src1467342663/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.12.0_db0@0.2.3_eslint@8.57.1_ioredis@5.4.2_m_sorrl4vftcouj5hfeu57rcyugi/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug___en",
    path: "/:slug(.*)*",
    component: () => import("/codebuild/output/src1467342663/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[...slug].vue")
  },
  {
    name: "IATA-airport-hotels-with-parking___en",
    path: "/:IATA()/airport-hotels-with-parking",
    component: () => import("/codebuild/output/src1467342663/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/airport-hotels-with-parking.vue")
  },
  {
    name: "IATA-airport-parking___en",
    path: "/:IATA()/airport-parking",
    component: () => import("/codebuild/output/src1467342663/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/airport-parking.vue")
  },
  {
    name: "IATA-airport-shuttles___en",
    path: "/:IATA()/airport-shuttles",
    component: () => import("/codebuild/output/src1467342663/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/airport-shuttles.vue")
  },
  {
    name: "IATA-guides___en",
    path: "/:IATA()/guides",
    component: () => import("/codebuild/output/src1467342663/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/guides.vue")
  },
  {
    name: "IATA-off-airport-parking___en",
    path: "/:IATA()/off-airport-parking",
    component: () => import("/codebuild/output/src1467342663/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/off-airport-parking.vue")
  },
  {
    name: "IATA-parking-coupons___en",
    path: "/:IATA()/parking-coupons",
    component: () => import("/codebuild/output/src1467342663/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/parking-coupons.vue")
  },
  {
    name: "IATA-port-hotels-with-parking___en",
    path: "/:IATA()/port-hotels-with-parking",
    component: () => import("/codebuild/output/src1467342663/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/port-hotels-with-parking.vue")
  },
  {
    name: "IATA-port-parking___en",
    path: "/:IATA()/port-parking",
    component: () => import("/codebuild/output/src1467342663/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/port-parking.vue")
  },
  {
    name: "flight-status-result___en",
    path: "/flight-status/:result()",
    component: () => import("/codebuild/output/src1467342663/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/flight-status/[result].vue")
  },
  {
    name: "health___en",
    path: "/health",
    component: () => import("/codebuild/output/src1467342663/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/health.vue")
  },
  {
    name: "hotel-package-code-name-IATA___en",
    path: "/hotel-package/:code()-:name()-:IATA()",
    component: () => import("/codebuild/output/src1467342663/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/hotel-package/[code]-[name]-[IATA].vue")
  },
  {
    name: "hro-code-name-IATA___en",
    path: "/hro/:code()-:name()-:IATA()",
    component: () => import("/codebuild/output/src1467342663/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/hro/[code]-[name]-[IATA].vue")
  },
  {
    name: "parking-code-name-IATA___en",
    path: "/parking/:code()-:name()-:IATA()",
    component: () => import("/codebuild/output/src1467342663/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/parking/[code]-[name]-[IATA].vue")
  },
  {
    name: "Guides___en",
    path: "/:IATA([a-z]{3})",
    component: () => import("/codebuild/output/src1467342663/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/guides.vue")
  },
  {
    name: component_45stubytvoIygjH1Meta?.name,
    path: "/sitemap.xml",
    component: component_45stubytvoIygjH1
  }
]